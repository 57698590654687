function zeroPrependedNumber(number: any) {
	// prepend '0'-string and use the two last items to get 0 before single digit months
	return ('0' + number).substring(-2, 2)
}

export default function formatDate(date: any) {
	const year = date.getFullYear()
	// getMonth() is 0-indexed, so we add 1
	const month = zeroPrependedNumber(date.getMonth() + 1)
	const day = zeroPrependedNumber(date.getDate())
	return `${year}-${month}-${day}`
}

/**
 * Safely formats a date according to locale and DateTimeFormatOptions.
 * Falls back to "toString()"-method if formatting fails.
 * @param date Date object or date-string.
 * @param locale optional locale. Falls back to browsers default locale if not specified.
 * @param options standard Intl.DateTimeFormat options. see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat}
 */
export function formatDateI18n(date?: string | Date, locale?: string, options?: Intl.DateTimeFormatOptions): string {
	if (!date) {
		return ''
	}
	try {
		return new Intl.DateTimeFormat(locale, options).format(new Date(date))
	} catch (e) {
		console.warn('Failed to format date', e)
		return date?.toString()
	}
}

/**
 * @param date Date object or date-string.
 * @returns ISO date string (yyyy-mm-dd)
 */
export function toISODate(date?: string | Date): string {
	if (!date) {
		return ''
	}
	try {
		return new Date(date).toISOString().substring(0, 10)
	} catch (e) {
		console.warn('Failed to format date', e)
		return date?.toString()
	}
}
